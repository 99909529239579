import ReactDOM from "react-dom";
import WarningIcon, { Props } from "./WarningIcon.react";

const renderDefaultWarningIcon = (props: Props): ((element: HTMLElement) => void) => {
  return (element: HTMLElement) => {
    ReactDOM.render(<WarningIcon {...props} />, element);
  };
};

export default renderDefaultWarningIcon;
