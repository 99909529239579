import { gql } from "@apollo/client";
import { apolloClient } from "../themes/utils/apolloClient";

function gqlQuery(viewer: string): any {
  return gql`
    query GuestCalendar {
      ${viewer} {
        guestCalendar {
          events {
            endDate
            from
            role
            name
            startDate
            type
            accesspointId
          }
        }
      }
    }
  `;
}

export function fetchGuestCalendar(token: string, guestStatus: string, cb: (events: any[]) => void): any {
  if (!token) {
    return cb([]);
  }

  const viewer = guestStatus === "registered" ? "viewer" : "unregisteredViewer";

  apolloClient(token).query({
    query: gqlQuery(viewer),
    fetchPolicy: "network-only"
  }).then(results => {
    cb(results.data[viewer].guestCalendar.events);
  });
}
