import TimeConflictChecker from "../time_conflict_checker/TimeConflictChecker";
import moment from "moment";

I18n.locale = window.eventmaker.locale || "en"; // so that graphql requests are made in the right language
moment.locale(I18n.locale);

const {
  guestStatus,
  timeConflictCheckingEnabled,
  timeConflictCheckingMode,
  guestApiToken,
  timezoneIndication
} = window.eventmaker.timeConflictChecker;

window.eventmaker.TimeConflictChecker = (config) => {
  return new TimeConflictChecker(guestApiToken, guestStatus, timeConflictCheckingEnabled, timeConflictCheckingMode, timezoneIndication, config);
};
