import renderDefaultWarningIcon from "./renderDefaultWarningIcon";
import { fetchGuestCalendar } from "./fetchGuestCalendar";
import moment from "moment";

type TimeConflictCheckerMode = "restrictive"|"informative";

const DefaultConfig = {
  startDateDataAttrKey: "start-date",
  endDateDataAttrKey: "end-date",
  eventRole: "participant",
  eventFrom: "guest",
  eventType: "session",
  eventNameDataAttrKey: "name",
  eventIdDataAttrKey: "accesspoint-id",
  rootElement: "document",
  extraElementsSelector: null
};

export const CONFLICTING_NODE_ATTR_KEY = "data-conflicting";

export default class TimeConflictChecker {
  private enabled: boolean;
  private events: any[];
  private config: any;
  private mode: TimeConflictCheckerMode;
  private timezoneIndication: string;

  constructor(token: string, guestStatus: string, enabled: boolean, mode: TimeConflictCheckerMode, timezoneIndication: string, config: any) {
    this.events = [];
    this.enabled = enabled;
    this.config = config;
    if (this.findNodes().length === 0) {
      this.enabled = false;
    }

    if (!this.enabled) return;

    this.mode = mode;
    this.timezoneIndication = timezoneIndication;
    fetchGuestCalendar(token, guestStatus, (events): void => {
      this.events = events.map(event => this.renameAccesspointIdField(event));
      this.config.onEventsLoaded && this.config.onEventsLoaded(this.events);
      this.runCheck();
    });
  }

  private renameAccesspointIdField(event: any): any {
    const { accesspointId, ...rest } = event;
    return { id: accesspointId, ...rest };
  }

  private findNodes(): NodeListOf<Element> {
    const query = `[data-${this.configValue("startDateDataAttrKey")}][data-${this.configValue("endDateDataAttrKey")}]`;
    const extraSelector = this.configValue("extraElementsSelector") || "";
    return eval(this.configValue("rootElement")).querySelectorAll(`${query}${extraSelector}`);
  }

  private isOverlapping(event: any, startDate: string, endDate: string): boolean {
    return moment(event.startDate).isBefore(endDate) && moment(event.endDate).isAfter(startDate);
  }

  private getOverlappingEvents({ startDate, endDate, id }): any[] {
    return this.events.filter(e => e.id !== id && this.isOverlapping(e, startDate, endDate));
  }

  private blockingEvent(event: any): boolean {
    return ["moderator", "speaker"].includes(event.role) || (event.role === "participant" && event.from === "guest");
  }

  private areEventsConflicting(event1: any, event2: any): boolean {
    return this.blockingEvent(event1) && this.blockingEvent(event2);
  }

  private getConflictingEvents(event: any): any[] {
    return this.getOverlappingEvents(event).filter(e => this.areEventsConflicting(event, e));
  }

  private configValue(key: string): string {
    return this.config[key] || DefaultConfig[key];
  }

  private nodeEventStartDate(node: Element): string|null {
    return node.getAttribute(`data-${this.configValue("startDateDataAttrKey")}`);
  }

  private nodeEventEndDate(node: Element): string|null {
    return node.getAttribute(`data-${this.configValue("endDateDataAttrKey")}`);
  }

  private nodeEventName(node: Element): string|null {
    return node.getAttribute(`data-${this.configValue("eventNameDataAttrKey")}`);
  }

  private nodeId(node: Element): string|null {
    return node.getAttribute(`data-${this.configValue("eventIdDataAttrKey")}`);
  }

  private eventFromNode(node: Element): any {
    return {
      "endDate": this.nodeEventEndDate(node),
      "from": this.configValue("eventFrom"),
      "name": this.nodeEventName(node),
      "role": this.configValue("eventRole"),
      "startDate": this.nodeEventStartDate(node),
      "type": this.configValue("eventType"),
      "id": this.nodeId(node)
    };
  }

  private isNodeConflicting(node: Element): boolean {
    return node.getAttribute(CONFLICTING_NODE_ATTR_KEY) === "true";
  }

  private markNodeHasConflicting(node: Element): void {
    node.setAttribute(CONFLICTING_NODE_ATTR_KEY, "true");
  }

  private unmarkNodeHasConflicting(node: Element): void {
    node.removeAttribute(CONFLICTING_NODE_ATTR_KEY);
  }

  runCheck(): void {
    if (!this.enabled) return;

    const nodes = this.findNodes();
    const { onConflict, onNoMoreConflict } = this.config;
    Array.from(nodes).forEach((node: Element) => {
      const conflictingWithEvents = this.getConflictingEvents(this.eventFromNode(node));
      if (conflictingWithEvents.length > 0) {
        const wasAlreadyConflicting = this.isNodeConflicting(node);
        this.markNodeHasConflicting(node);
        onConflict && onConflict(node, conflictingWithEvents, this.mode, wasAlreadyConflicting, renderDefaultWarningIcon({ events: conflictingWithEvents, timezoneIndication: this.timezoneIndication }));
      } else if (this.isNodeConflicting(node)) {
        this.unmarkNodeHasConflicting(node);
        onNoMoreConflict && onNoMoreConflict(node, this.mode);
      }
    });
  }

  addEvent(node: HTMLElement): void {
    if (!this.enabled) return;

    this.events.push(this.eventFromNode(node));
    this.runCheck();
  }

  removeEvent(node: HTMLElement): void {
    if (!this.enabled) return;

    const id = this.nodeId(node);
    const index = this.events.findIndex(element => element.id === id);
    if (index === -1) return;

    this.events.splice(index, 1);
    this.runCheck();
  }
}
