import { OverlayTrigger, Popover } from "react-bootstrap";
import moment from "moment";
import truncate from "lodash/truncate";

export interface Props {
  events: any[];
  timezoneIndication?: string;
}

const i18n = (key: string, options = {}): string => {
  return I18n.t(`time_conflict_checker.warning_icon.${key}`, options);
};

const MAX_EVENTS = 3;

const WarningIcon: React.FC<Props> = ({
  events: conflictingEvents,
  timezoneIndication
}): JSX.Element => {

  const formatEventDateRange = ({ startDate, endDate }): string => {
    const start = I18n.l("time.formats.short", startDate);
    const momentEndDate = moment(endDate);
    const end = (moment(startDate).isSame(momentEndDate, "day")) ? (
      momentEndDate.format("LT")
    ) : (
      I18n.l("time.formats.short", endDate)
    );

    let result = [start, end].join(" - ");
    if (timezoneIndication) {
      result = `${result} ${timezoneIndication}`;
    }

    return result;
  };

  const events = conflictingEvents.slice(0, MAX_EVENTS);

  const renderEvent = (event: any): JSX.Element => {
    return (
      <div key={event.id} style={{ borderRadius: "6px", border: "1px solid #0F172A", padding: "5px", marginBottom: events.length > 1 ? "5px" : null }}>
        <small>{formatEventDateRange(event)}</small><br />
        <strong>{truncate(event.name, { length: 50 })}</strong>
      </div>
    );
  };

  const more = conflictingEvents.length - events.length;

  const popover = (
    <Popover id="popover" className="popover-time-conflict">
      <div className="popover-content">
        <p style={{ paddingTop: 0 }}>{i18n("title")}</p>
        {events.map(e => renderEvent(e))}
        {more > 0 && (
          <p className="small">{i18n("and_x_more", { count: more })}</p>
        )}
      </div>
    </Popover>
  );

  return (
    <OverlayTrigger placement="top" overlay={popover}>
      <span
        className="fa-regular fa-calendar-exclamation text-danger"
        style={{ fontSize: "16px" }}
        onClick={(e): void => {
          e.preventDefault();
          e.stopPropagation();
        }}></span>
    </OverlayTrigger>
  );
};

export default WarningIcon;
